<template>
    <div class="component-vimeo-player sixteen-nine">
        <div class="content">
            <VueVimeoPlayer
                ref="playerElm"
                v-on:play="showMute = true"
                v-on:end="showMute = false"
                v-on:pause="showMute = false"
                :videoId="videoId"
                :options="options"
            ></VueVimeoPlayer>
            <div class="video-controls">
                <a
                    v-show="showMute"
                    href="javascript:void(0)"
                    @click="toggleMute()"
                    class="video-control-button"
                >
                    <span class="button-message">{{ controlMuteMessage }}</span>
                    <div class="button-icon">
                        <div
                            class="icon-overlay"
                            @click.stop="toggleMute()"
                        ></div>
                        <embed
                            v-show="!!muteSvg"
                            @click.stop="toggleMute()"
                            :src="muteSvg"
                        />
                        <img
                            v-show="!!muteImg"
                            @click.stop="toggleMute()"
                            :src="muteImg"
                            :alt="controlMuteMessage"
                        />
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
    data() {
        return {
            showMute: false,
            controlMuteMessage: "",
        };
    },
    components: {
        VueVimeoPlayer: vueVimeoPlayer,
    },
    props: {
        videoId: String,
        autoplay: Boolean,
        loop: Boolean,
        title: { type: Boolean, default: false },
        byline: { type: Boolean, default: false },
        portrait: { type: Boolean, default: false },
        muted: Boolean,
        responsive: { type: Boolean, default: true },
        muteSvg: String,
        muteImg: String,
        muteMessage: { type: String, default: "Mute" },
        unmuteMessage: { type: String, default: "Unmute" },
    },
    methods: {
        async syncMuteState() {
            const isMuted = await this.$refs.playerElm.player.getMuted();
            this.controlMuteMessage = isMuted
                ? this.unmuteMessage
                : this.muteMessage;
        },
        async toggleMute() {
            const isMuted = await this.$refs.playerElm.player.getMuted();
            if (isMuted) {
                await this.$refs.playerElm.unmute();
            } else {
                await this.$refs.playerElm.mute();
            }
            await this.syncMuteState();
        },
    },
    async mounted() {
        await this.syncMuteState();
        this.showMute = true;
    },
    computed: {
        options() {
            return {
                muted: this.muted,
                loop: this.loop,
                title: this.title,
                byline: this.byline,
                portrait: this.portrait,
                autoplay: this.autoplay,
                responsive: this.responsive,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-vimeo-player {
    position: relative;
    font-family: Helvetica, Arial, sans-serif;
}

.video-controls {
    position: absolute;
    left: 10px;
    bottom: 60px;
    .video-control-button {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #23211a;
        background-color: #ffffff;
        padding: 3px 5px;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.75;
        cursor: pointer;

        & > .button-icon {
            display: flex;
            position: relative;

            & > .icon-overlay {
                position: absolute;
                display: block;
                z-index: 10;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            & > embed {
                max-width: 20px;
                max-height: 20px;
                margin-left: 3px;
                cursor: pointer;
            }
        }
    }
}
</style>
